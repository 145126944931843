<template>
  <div class="content-wrapper">
    <!-- Main content -->
    <section class="content">
      <a-row style="display: flex">
        <a-col><breadcrumb></breadcrumb></a-col>
      </a-row>
      <section style="margin-bottom: 1%">
        <a-button type="primary" @click="createHandle">
          <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
          {{ $t('新增应付单') }}
        </a-button>
      </section>
      <a-row :gutter="16">
        <a-col :span="6">
          <a-input
            allow-clear
            v-model.trim="formData.orderSn"
            :placeholder="$t('请输入应付单号')"
          />
        </a-col>
        <a-col :span="6">
          <DateRangePicker
            id="effectiveTime"
            :needhms="false"
            @get_date="chooseAccountingDate"
            :placeholder="$t(`记账日期`)"
            :start-time="formData.accountingDateStart"
            :end-time="formData.accountingDateEnd"
          />
        </a-col>
        <a-col :span="6">
          <DateRangePicker
            id="effectiveTime"
            :needhms="false"
            @get_date="chooseBizTime"
            :placeholder="$t(`业务日期`)"
            :start-time="formData.bizTimeStart"
            :end-time="formData.bizTimeEnd"
          />
        </a-col>
        <a-col :span="6">
          <SelectSupplier @supplierIndexChange="supplierIndexChange"></SelectSupplier>
        </a-col>
      </a-row>
      <a-row :gutter="16" style="margin-top: 15px">
        <a-col :span="6">
          <CommonSelect
            :placeholder="$t('单据类型')"
            :list="payable_order_type"
            :code.sync="formData.orderType"
          />
        </a-col>
        <a-col :span="6">
          <CommonSelect
            :list="payable_order_invoice_status"
            :code.sync="formData.invoiceStatus"
            :placeholder="$t('开票状态')"
          />
        </a-col>
        <a-col :span="6">
          <CommonSelect
            :list="payable_order_biz_status"
            :code.sync="formData.bizOrderStatus"
            :placeholder="$t('业务状态')"
          />
        </a-col>
        <a-col :span="6">
          <a-button @click="searchHandle(1)" type="primary">
            <i class="iconfont" style="margin-right: 5px">&#xe647;</i>
            {{ $t(`form.Search`) }}
          </a-button>
          <!-- <a-button @click="onExport" style="margin-left: 15px">
            <i class="icon iconfont icon-upload" style="margin-right: 5px"></i>
            {{ $t(`form.Export`) }}
          </a-button> -->
        </a-col>
      </a-row>

      <CommonTable
        class="mt10"
        ref="CommonTableRef"
        :statusList="payable_order_status"
        :showSelect="true"
        :selectedRowKeys.sync="selectedRowKeys"
        :scroll="{ x: 2000, y: scrollHeight }"
        :loading="tableLoading"
        :columns="tableColumns"
        :data-source="tableData"
        :total="total"
        @select="onSelectChange"
        @search="queryOrderList"
      >
        <template v-slot:header>
          <div class="flex-ct">
            <a-button
              type="primary"
              @click="createInvoiceHandle"
              :disabled="selectedRows.length === 0"
            >
              <a-popover placement="topLeft">
                <template slot="content">
                  <div>
                    {{ $t('将应付单的商品信息带到发票创建页面') }}
                  </div>
                </template>
                <span>{{ $t('推送开票') }}</span>
                <a-icon type="question-circle" class="ml6" />
              </a-popover>
            </a-button>
            <!-- 同一个供应商、待核销/部分核销状态 planList里面status: "NORMAL"可以下推生成付款申请单、付款单 -->
            <PushBtn
              class="ml10"
              :selectedRows="selectedRows"
              :disabled="selectedRows.length === 0"
            />
            <VoucherBtn
              class="ml10"
              docType="PayableOrder"
              :selectedRows="selectedRows"
              :disabled="selectedRows.length === 0"
              @close="searchHandle"
            />
          </div>
        </template>
        <!-- 应付单号 -->
        <template v-slot:orderSn="{ text, record }">
          <a @click="viewDetail(record)">{{ text }}</a>
        </template>
        <template v-slot:sourceOrderCode="{ record }">
          <a>{{ record.sourceOrderCode }}</a>
        </template>
        <!-- 业务单号 -->
        <template v-slot:otherSns="{ record }">
          <div>
            {{ $t('采购单号') }}:
            <span class="text-link">{{ record.purchaseOrderSn }}</span>
          </div>
          <div>
            {{ $t('预到货通知单号') }}:
            <span class="text-link">{{ record.asnOrderSn }}</span>
          </div>
          <div>
            {{ $t('入库单号') }}:
            <span class="text-link">{{ record.receivedOrderSn }}</span>
          </div>
        </template>
        <!-- 金额（未含税） -->
        <template v-slot:amountWithoutTax="{ text }">¥ {{ text | toThousands }}</template>
        <!-- 税额 -->
        <template v-slot:taxAmount="{ text }">¥ {{ text | toThousands }}</template>
        <!-- 价税合计 -->
        <template v-slot:payableAmount="{ text }">¥ {{ text | toThousands }}</template>
        <!-- 未核销金额 -->
        <template v-slot:writtenOffAmount="{ text, record }">
          ¥ {{ subtract(record.payableAmount, text) | toThousands }}
        </template>
        <!-- 开票金额 -->
        <template v-slot:invoicedAmount="{ text }">
          <span v-if="text">¥ {{ text | toThousands }}</span>
        </template>
      </CommonTable>
    </section>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import DateRangePicker from '@component/dateRangePicker'
import SelectSupplier from '@component/selectSupplier'
import CommonSelect from '@component/commonSelect'
import CommonTable from '@component/commonTable'
import PushBtn from '@component/pushBtn'
import VoucherBtn from '@component/voucherBtn'
import { convertKeysToCamelCase, spaceToComma } from '@/common'
import { subtract } from '@/common/number'
export default {
  name: 'payableList',
  activated() {
    this.searchHandle()
  },
  components: {
    DateRangePicker,
    SelectSupplier,
    CommonSelect,
    CommonTable,
    PushBtn,
    VoucherBtn,
  },
  data() {
    return {
      formData: {
        orderSn: undefined,
        referenceNo: undefined,
        bizTimeStart: undefined,
        bizTimeEnd: undefined,
        accountingDateStart: undefined,
        accountingDateEnd: undefined,
        invoiceStatus: undefined,
        bizOrderStatus: undefined,
        status: '',
      },
      tableLoading: false,
      total: 0,
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      visible: false,
      nextVisible: false,
    }
  },
  computed: {
    ...mapState([
      'payable_order_status',
      'payable_order_type',
      'payable_order_invoice_status',
      'payable_order_biz_status',
    ]),
    ...mapGetters([
      'payableOrderStatusMapping',
      'payableOrderTypeMapping',
      'invoiceTypeMapping',
      'payableOrderInvoiceStatusMapping',
      'sourceOrderTypeMapping',
      'bizOrderStatusMapping',
    ]),
    scrollHeight() {
      return window.innerHeight - 470
    },
    tableColumns() {
      return [
        {
          dataIndex: 'orderSn',
          title: this.$t('应付单号'),
          key: 'orderSn',
          width: 200,
          scopedSlots: { customRender: 'orderSn' },
        },
        {
          title: this.$t('应付单状态'),
          width: 150,
          dataIndex: 'status',
          customRender: (text) => this.payableOrderStatusMapping[text],
        },
        {
          title: this.$t('应付单类型'),
          width: 150,
          dataIndex: 'orderType',
          customRender: (text) => this.payableOrderTypeMapping[text],
        },
        {
          title: this.$t('供应商'),
          width: 150,
          dataIndex: 'supplierName',
        },
        {
          title: this.$t('记账日期'),
          width: 200,
          dataIndex: 'accountingDate',
        },
        {
          title: this.$t('金额') + '(' + this.$t('未含税') + ')',
          width: 150,
          dataIndex: 'amountWithoutTax',
          scopedSlots: { customRender: 'amountWithoutTax' },
        },
        {
          title: this.$t('税额'),
          width: 150,
          dataIndex: 'taxAmount',
          scopedSlots: { customRender: 'taxAmount' },
        },
        {
          title: this.$t('价税合计'),
          width: 150,
          dataIndex: 'payableAmount',
          scopedSlots: { customRender: 'payableAmount' },
        },
        {
          title: this.$t('未核销金额'),
          width: 150,
          dataIndex: 'writtenOffAmount',
          scopedSlots: { customRender: 'writtenOffAmount' },
        },
        {
          title: this.$t('分期付款次数'),
          width: 100,
          dataIndex: 'installments',
        },
        {
          title: this.$t('预付款比例') + '(%)',
          width: 150,
          dataIndex: 'prepaymentRatio',
        },
        {
          title: this.$t('是否生成凭证'),
          dataIndex: 'voucherGenerated',
          width: 100,
          customRender: (text) => (text ? this.$t('是') : this.$t('否')),
        },
        {
          title: this.$t('凭证号'),
          dataIndex: 'voucherNo',
          width: 100,
        },
        {
          title: this.$t('业务单号'),
          dataIndex: 'otherSns',
          width: 320,
          scopedSlots: { customRender: 'otherSns' },
        },
        {
          title: this.$t('业务状态'),
          dataIndex: 'bizOrderStatus',
          width: 150,
          customRender: (text) => this.bizOrderStatusMapping[text],
        },
        {
          title: this.$t('开票金额'),
          dataIndex: 'invoicedAmount',
          width: 150,
          scopedSlots: { customRender: 'invoicedAmount' },
        },
        {
          title: this.$t('开票状态'),
          dataIndex: 'invoiceStatus',
          width: 150,
          customRender: (text) => this.payableOrderInvoiceStatusMapping[text],
        },
        {
          title: this.$t('开票时间'),
          dataIndex: 'invoiceTime',
          width: 200,
        },
        {
          title: this.$t('源单类型'),
          dataIndex: 'sourceOrderType',
          width: 150,
          customRender: (text) => this.sourceOrderTypeMapping[text],
        },
        {
          title: this.$t('源单编码'),
          dataIndex: 'sourceOrderCode',
          width: 180,
          scopedSlots: { customRender: 'sourceOrderCode' },
        },
        {
          title: this.$t('form.Remark'),
          width: 120,
          dataIndex: 'remark',
        },
        {
          title: this.$t('form.CreateAt'),
          dataIndex: 'createdAt',
          width: 200,
          sorter: true,
        },
      ]
    },
  },
  methods: {
    ...mapMutations(['addPane']),
    subtract,
    supplierIndexChange(val) {
      this.formData.supplierName = val.supplierName
    },
    createHandle() {
      this.$router.push({
        name: 'payableDetail',
      })
    },
    viewDetail(record) {
      this.addPane({
        view: 'payableDetail/' + record.orderSn,
        name: 'detailSn',
        query: {
          id: record.id,
          sn: record.orderSn,
        },
        route: `/payableManage/payableDetail?id=${record.id}&sn=${record.orderSn}`,
      })
    },
    chooseBizTime(date) {
      this.formData.bizTimeStart = date.start_time
      this.formData.bizTimeEnd = date.end_time
    },
    chooseAccountingDate(date) {
      this.formData.accountingDateStart = date.start_time
      this.formData.accountingDateEnd = date.end_time
    },
    async queryOrderList(params) {
      let data = {
        ...this.formData,
        ...params,
      }
      let orderSn = spaceToComma(this.formData.orderSn)
      if (orderSn.includes(',')) {
        data.orderSnList = orderSn.split(',')
      } else if (orderSn) {
        data.orderSn = orderSn
      }
      this.tableLoading = true
      let res = await http({
        type: 'post',
        url: api.getPayableList,
        data,
      })
      let purchaseOrderList = [...(res.result.list || '')]
      this.tableData = convertKeysToCamelCase(purchaseOrderList)
      this.total = res.result.total
      this.tableLoading = false
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    createInvoiceHandle() {
      if (this.selectedRowKeys.length == 0) {
        this.$message.warning(this.$t('请选择需要创建的订单'))
        return
      }
      let list = []
      this.selectedRows.forEach((item) => {
        list.push(...item.itemList)
      })
      localStorage.setItem('payableOrderPushdata', JSON.stringify(list))
      this.$router.push({
        name: 'invoiceDetail',
      })
    },
    searchHandle(pageNo) {
      this.selectedRowKeys = []
      this.selectedRows = []
      this.$refs.CommonTableRef.queryList(pageNo)
    },
  },
}
</script>
